module.exports = [{
      plugin: require('/Users/yangd/xueq/snowball-x/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1500},
    },{
      plugin: require('/Users/yangd/xueq/snowball-x/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/yangd/xueq/snowball-x/src/cms/cms.js"},
    },{
      plugin: require('/Users/yangd/xueq/snowball-x/node_modules/gatsby-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":800},
    },{
      plugin: require('/Users/yangd/xueq/snowball-x/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
