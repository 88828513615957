// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/index-page/index.js" /* webpackChunkName: "component---src-templates-index-page-index-js" */),
  "component---src-templates-thank-you-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/thank-you/index.js" /* webpackChunkName: "component---src-templates-thank-you-index-js" */),
  "component---src-templates-terms-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/terms/index.js" /* webpackChunkName: "component---src-templates-terms-index-js" */),
  "component---src-templates-privacy-policy-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/privacy-policy/index.js" /* webpackChunkName: "component---src-templates-privacy-policy-index-js" */),
  "component---src-templates-how-it-works-page-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/how-it-works-page/index.js" /* webpackChunkName: "component---src-templates-how-it-works-page-index-js" */),
  "component---src-templates-price-page-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/price-page/index.js" /* webpackChunkName: "component---src-templates-price-page-index-js" */),
  "component---src-templates-risk-disclosure-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/risk-disclosure/index.js" /* webpackChunkName: "component---src-templates-risk-disclosure-index-js" */),
  "component---src-templates-pricing-pages-us-stock-options-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/pricing-pages/us-stock-options/index.js" /* webpackChunkName: "component---src-templates-pricing-pages-us-stock-options-index-js" */),
  "component---src-templates-pricing-pages-us-stocks-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/pricing-pages/us-stocks/index.js" /* webpackChunkName: "component---src-templates-pricing-pages-us-stocks-index-js" */),
  "component---src-templates-pricing-pages-hk-warrants-cbbcs-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/pricing-pages/hk-warrants-cbbcs/index.js" /* webpackChunkName: "component---src-templates-pricing-pages-hk-warrants-cbbcs-index-js" */),
  "component---src-templates-pricing-pages-hk-stock-options-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/pricing-pages/hk-stock-options/index.js" /* webpackChunkName: "component---src-templates-pricing-pages-hk-stock-options-index-js" */),
  "component---src-templates-pricing-pages-shanghai-shenzhen-stocks-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/pricing-pages/shanghai-shenzhen-stocks/index.js" /* webpackChunkName: "component---src-templates-pricing-pages-shanghai-shenzhen-stocks-index-js" */),
  "component---src-templates-pricing-pages-financing-interest-rates-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/pricing-pages/financing-interest-rates/index.js" /* webpackChunkName: "component---src-templates-pricing-pages-financing-interest-rates-index-js" */),
  "component---src-templates-download-page-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/download-page/index.js" /* webpackChunkName: "component---src-templates-download-page-index-js" */),
  "component---src-templates-contact-page-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/contact-page/index.js" /* webpackChunkName: "component---src-templates-contact-page-index-js" */),
  "component---src-templates-faq-page-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/faq-page/index.js" /* webpackChunkName: "component---src-templates-faq-page-index-js" */),
  "component---src-templates-blog-page-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/blog-page/index.js" /* webpackChunkName: "component---src-templates-blog-page-index-js" */),
  "component---src-templates-about-page-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/about-page/index.js" /* webpackChunkName: "component---src-templates-about-page-index-js" */),
  "component---src-templates-pricing-pages-hk-stocks-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/pricing-pages/hk-stocks/index.js" /* webpackChunkName: "component---src-templates-pricing-pages-hk-stocks-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("/Users/yangd/xueq/snowball-x/src/templates/blog-post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-pages-404-js": () => import("/Users/yangd/xueq/snowball-x/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

